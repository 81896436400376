<template>
  <mapbox
    :access-token="accessToken"
    @map-load="onMapLoaded"
    :map-options="{
      style: mapStyle,
      center: center,
      zoom: zoom
    }"
    :geolocate-control="{
      show: true,
      position: 'top-left'
    }"
    :fullscreen-control="{
      show: true,
      position: 'top-left'
    }"
  />
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import mapboxgl from 'mapbox-gl';

export default {
  props: {
    point: Array,
    informasi: Object
  },
  components: { Mapbox },
  data() {
    return {
      mapbox: null,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      center: this.point,
      zoom: 15,
      accessToken:
        'pk.eyJ1IjoidmNvbmZpY2tlciIsImEiOiJjazJ5ZGtyMGIwNnliM2NvOGVvZnhoOGMwIn0.-5kuq-J8FTqiYeNAv_qwRA',
      geojson: []
    };
  },
  mounted() {},

  methods: {
    onMapLoaded(map) {
      let that = this;
      map.easeTo({
        center: that.center,
        zoom: that.zoom
      });
      for (var icon_name in window.map_icons) {
        let a = icon_name;
        map.loadImage(window.map_icons[a], function(error, image) {
          map.addImage(a, image);
        });
      }
      setTimeout(() => {
        var html = `<div class="row">
                      <div class="col-md-12">
                      <h6 class=" text-uppercase mb-1">Bencana ${that.informasi.status}</h6>
                        <p class=" title-font marker-detail">
                          <strong>Nama Bencana </strong>: ${that.informasi.nama_bencana}<br>
                          <strong>Jenis Bencana </strong>: ${that.informasi.tipe_bencana}<br>
                          <strong>Penyebab </strong>: ${that.informasi.penyebab}<br>
                          <strong>Waktu Kejadian </strong>: ${that.informasi.waktu_kejadian}<br>
                          <strong>Kab/Kota </strong>: ${that.informasi.nmkab}<br>
                          <strong>Latitude  </strong>: ${that.informasi.latitude}<br>
                          <strong>Longitude  </strong>: ${that.informasi.longitude}<br>
                          <strong>Wilayah Terdampak  </strong>:`;
        for (let index = 0; index < that.informasi.terdampak.length; index++) {
          if (index < that.informasi.terdampak.length - 1) {
            html += `${that.informasi.terdampak[index]},`;
          } else {
            html += `${that.informasi.terdampak[index]}`;
          }
        }
        html += `    </p>
                      </div>
                    </div>`;
        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    description: html
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: this.center
                  }
                }
              ]
            }
          },
          layout: {
            'icon-image': 'cat',
            'icon-size': 0.11
          }
        });
        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'points', function() {
          map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'points', function() {
          map.getCanvas().style.cursor = '';
        });

        map.on('click', 'points', function(e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
        });
      }, 1000);
    }
  }
};
</script>

<style>
#map {
  height: 500px;
  width: 100%;
}
.header-marker {
  background-color: blue;
  color: white;
  font-size: 14px;
}
.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-popup-content strong {
  font-family: 'Museo Sans 700', sans-serif !important;
}
/* .mapboxgl-popup-content{
  width: 500px
} */
</style>
