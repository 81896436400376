<template>
  <section class="section section-standalone" id="alert-disaster">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Alert Disaster
          </h1>
        </div>
        <div class="row mt-3" v-if="alert.length > 0">
          <div class="col-md-12">
            <div
              class="bg-white rounded border card-dashboard border-radius-style h-100"
            >
              <div
                class="card-dashboard-title border-bottom p-2 pl-3 pr-3 bg-danger text-white rounded-left d-flex justify-content-between align-items-center border-radius-style rounded-right-0"
              >
                <div class="flex-row d-flex align-items-center">
                  <img
                    src="@/assets/img/icon/icon-ring-white.svg"
                    alt
                    width="25"
                  />
                  <h5
                    class="mb-0 ml-3 text-uppercase text-size-small primary-font-bold font-weight-bold"
                  >
                    Sedang Terjadi Bencana
                  </h5>
                </div>
                <div class="flex-row">
                  <h5
                    class="mb-0 text-uppercase text-size-small primary-font-bold font-weight-bold"
                  >
                    {{ locale_time.getDate() }}
                    {{ getMonth }}
                    {{ locale_time.getFullYear() }}
                  </h5>
                </div>
              </div>
              <div class="card-dashboard-content p-3">
                <div
                  class="alert alert-danger border-0 text-danger d-flex align-items-center justify-content-between mb-3 p-2 pl-3 pr-3 border-radius-style rounded-right-0"
                  role="alert"
                  v-for="(item, key) in alert"
                  :key="key"
                  v-on:click="loadDetailAlert(item)"
                >
                  <div class="flex-row d-flex align-items-center">
                    <img src="@/assets/img/icon/icon-ring.svg" alt width="25" />
                    <a
                      href="#modalAlert"
                      data-toggle="modal"
                      data-target="#modalAlert"
                    >
                      <h4
                        class="mb-0 ml-3 text-uppercase text-light-red primary-font-bold font-weight-bold text-size-small"
                      >
                        {{ item.nama_bencana }} {{ item.waktu_kejadian }}
                      </h4>
                    </a>
                  </div>
                  <div class="flex-row">
                    <h4
                      class="mb-0 text-uppercase text-light-red primary-font-bold font-weight-bold text-size-small"
                    >
                      {{ item.tipe_bencana }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 doc">
            <iframe
              :src="iframe"
              width="100%"
              height="440px"
              scrolling="yes"
              frameborder="0"
            />
          </div>
        </div>
        <div
          class="row sumber-data"
          v-if="sumber_data != null"
          v-tooltip.top-start="{
            content: `Pic : ${
              sumber_data.pic == null ? '-' : sumber_data.pic
            } <br> 
                                    Kontak : ${
                                      sumber_data.kontak == null
                                        ? '-'
                                        : sumber_data.kontak
                                    }`,
            show: sumberTooltip,
            trigger: 'manual'
          }"
          style="cursor:pointer"
          v-on:mouseover="changeSumberTooltip"
          v-on:click="changeSumberTooltip"
        >
          <div class="col-md-12 ">
            <p class="text-size-normal ">
              &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
              &nbsp; Pembaharuan Terakhir :
              {{
                sumber_data.updated_at == null ? '-' : sumber_data.updated_at
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalAlert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalScrollableTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-danger">
            <div class="d-flex align-items-center">
              <img src="@/assets/img/icon/icon-ring-white.svg" alt width="25" />
              <h4
                class="mb-0 ml-3 text-uppercase text-size-small primary-font-bold text-white font-weight-bold"
              >
                Sedang Terjadi Bencana
              </h4>
            </div>
          </div>
          <div class="modal-body p-0">
            <Map
              :point="point"
              :informasi="informasi"
              v-if="point.length > 0"
              width="100%"
              height="100px"
            />
            <!-- <img src="@/assets/img/maps-w-marker.png" alt class="img-responsive" /> -->
          </div>
          <!-- <div class="modal-footer justify-content-center flex-column bg-light-pink p-4">
                        <div class="bg-white rounded-lg w-100 d-flex align-items-center p-3 mb-3">
                            <div class="flex-row mr-3">
                                <i class="fa fa-circle fa-2x text-warning"></i>
                            </div>
                            <div class="flex-row">
                                <h4 class="text-size-small font-weight-normal">Lokasi</h4>
                                <h6 class="text-size-small text-gray font-weight-normal">{{ modal.kab_kota }}</h6>
                            </div>
                        </div>
                        <div class="bg-white rounded-lg w-100 d-flex align-items-center p-3 mb-3">
                            <div class="flex-row mr-3">
                                <i class="fa fa-circle fa-2x text-warning"></i>
                            </div>
                            <div class="flex-row">
                                <h4 class="text-size-small font-weight-normal">Bencana</h4>
                                <h6 class="text-size-small text-gray font-weight-normal">{{ modal.nama_bencana }}</h6>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Map from '@/components/Map.vue';
import { mapState } from 'vuex';
let date = new Date(window.date_now);
export default {
  components: {
    Map
  },
  metaInfo() {
    return {
      title: 'Disaster'
    };
  },
  computed: {
    getMonth() {
      const monthNames = window.date_month;

      return monthNames[date.getMonth()];
    },
    iframe() {
      return window.links['disaster' + (this.isDarkMode ? '_dark' : '')];
    },
    ...mapState(['isDarkMode'])
  },
  data() {
    return {
      sumberTooltip: false,
      alert: [],
      point: [],
      informasi: {},
      locale_time: date,
      sumber_data: {
        sumber: 'BPBD (Badan Penanggulangan Bencana Daerah)',
        updated_at: null
      }
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    loadData() {
      var body = document.querySelector('body');
      window.axios.post(window.base_api + 'page/disaster').then(response => {
        if (response.status == 200) {
          this.alert = response.data.alert;
          this.sumber_data = response.data.sumber_data;
          if (this.alert.length == 0) {
            body.style.overflowY = 'hidden';
          } else {
            body.style.overflowY = 'visible';
          }
        }
      });
    },
    loadDetailAlert(item) {
      setTimeout(() => {
        window.$('#modalAlert').on('shown.bs.modal', () => {
          this.point = [item.longitude, item.latitude];
          this.informasi = item;
        });

        window.$('#modalAlert').on('hidden.bs.modal', () => {
          this.point = [];
        });
      }, 100);
    }
  }
};
</script>
<style scoped>
.doc {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  iframe {
    height: 1000px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  iframe {
    height: 475px !important;
  }
}
</style>
